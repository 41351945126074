
:root {
  --font-default: Open Sans, system-ui;
  --font-primary: Open Sans, sans-serif;
  --font-secondary: Poppins, sans-serif;
}

/* Colors */
:root {
  --color-default: #3a3a3a;
  --color-primary: #2fa8dd;
  --color-secondary: #0e1d34;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
}

select::-ms-expand {
  display: none;
}


a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: #406aff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

.color-txt1{
  color: var(--color-primary);
  }
/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #001973;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-header span {
  position: absolute;
  top: 46px;
  color: rgba(14, 29, 52, 0.06);
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 56px;
  text-transform: uppercase;
  line-height: 0;
}

.section-header p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}


@media (max-width: 640px) {
  .section-header h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-header span {
    font-size: 38px;
  }
}


/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 5%;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(12, 115, 211, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #000;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  -webkit-animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 30px 0;
}

.header.sticked {
  background: rgba(18, 23, 30, 0.9);
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(14, 29, 52, 0.1);
}

.header .logo img {
  max-height: 1.2em;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: #fff;
  font-family: var(--font-primary);
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 10px 0px;
    font-family: var(--font-primary);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar > ul > li > a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 3px;
    background-color: var(--color-primary);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }
.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
  visibility: visible;
  width: 25px;
}
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    padding: 6px 18px;
    border-radius:5px;
    color: rgb(206, 206, 206);
    border-style: solid;
    border-width: 2px;
  }

  .navbar .get-a-quote:hover:before{
    visibility: hidden;
  }
  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    border-color:var(--color-primary);
    background-color: var(--color-primary);
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: var(--color-secondary);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(14, 29, 52, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    border-radius: 4px;
    margin: 15px;
    color: #fff;
  }

  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: rgba(13, 66, 255, 0.8);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #19335c;
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    margin-right: 10px;
  }

  .mobile-nav-hide {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(14, 29, 52, 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
 /* background: linear-gradient(rgba(33, 122, 159, 0.6), rgba(34, 111, 145, 0.9)), url("../img/syn-bmk2bg.jpg") center center;*/
  background: url("../img/syn-bmk2bg.jpg");
  background-size: cover;

  padding: 100px 0;
  box-shadow: 0px 0 30px rgba(56, 56, 56, 0.7);
}

@media (min-width: 1300px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding-bottom: 0;
}

.features .icon-list i {
  margin-right: 10px;
  font-size: 24px;
  line-height: 1.2;
}

.features .icon-list span {
  font-size: 18px;
  color: #29486a;
}

.features .note-cloud {
  margin-top: 100px;
  padding: 40px 0;
  margin-bottom: 80px;
}

.features .note-wrap {
  
  position: absolute;
  left: 0;
  top: 30px;
}

@media (max-width: 998px) {
  .features .note-wrap {
    position: relative;
  }
}

.features .note-wrap img {
  width: 610px;
}

@media (max-width: 1200px) {
  .features .note-wrap img {
    width: 340px;
    margin-top: 30px;
  }
}

@media (max-width: 998px) {
  .features .note-wrap img {
    width: 100%;
  }
}

.features .phone-wrap {
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .features .phone-wrap {
    position: relative;
  }
}

.features .phone-wrap img {
  width: 680px;
}

@media (max-width: 992px) {
  .features .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .features .phone-wrap img {
    width: 70%;
    align-items: center;
    justify-content: center;
  }
}

.features .details {
  margin-top: 100px;
  padding: 40px 0;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.features .details h4 {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}



.features .details .btn-get-started {
  display: inline-block;
  letter-spacing: 1px;
  padding: 10px 32px;
  margin-top:1rem;
  border-radius: 50px;
  transition: 0.5s;
  background-color: var(--color-primary);
  color: #fff;
}

.features .details .btn-get-started img{
  height: 1.5em;
}
.features .details .btn-get-started:hover {
  background: #167eae;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  font-size: 20px;
  background: var(--color-primary);
  color: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-secondary);
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(14, 29, 52, 0.8);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d3dff3;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #c2cbdf;
  margin: 20px 0;
  color: var(--color-secondary);
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

.hero {
  width: 100%;
  min-height:100vh;
  background: #000000;
 /* background-image: url("../img/hero-image.png");*/
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 120px 0 60px 0;
  color: #ffffffcc;
}

.hero h2 {
  margin-bottom: 20px;
  padding: 0;
  font-size: 48px;
  color: #dfdfdf;
}

.hero span {
  margin-bottom: 20px;
  padding: 0;
  font-size: 48px;
  color: #dfdfdf;
}

#hero_title_first {
  color:#2fa8dd
}



.hero h4 {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0;
  color: #ffffffcc;
  font-weight:lighter;
}

.hero h3 {
  margin-top: 20px;
  padding: 0;
  color: #ecebeb;
}
.hero h5 {
  margin-bottom: 30px;
  padding: 0;
  color: #ffffffcc;
  font-weight:lighter;
  color: #7b7b7b;
}

.hero h6 {
  margin-bottom: 30px;
  font-weight: 700;
  padding: 0;
  color: #6f6f6f;
}
.hero blockquote p{
  color: #fff;
  padding-left: 20px;
  font-weight: 600;
  font-size: 20px;
  border-left: 2px solid var(--color-primary);
  margin: 40px 0;
}
@media (max-width: 575px) {
  .hero h5 {
    font-size: 15px;
  }
  .hero h4 {
    font-size: 16px;
  }
  .hero span {
    font-size: 25px;

  }
}

.hero p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 40px;
}

.hero form {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.hero form .form-control {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  margin-right: 10px;
  border: none !important;
  background: none !important;
}

.hero form .form-control:hover,
.hero form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.hero form .btn-primary {
  background-color: var(--color-primary);
  padding: 15px 30px;
}

.hero form .btn-primary:hover {
  background-color: #2756ff;
}

.hero .stats-item {
  padding: 30px;
  width: 100%;
}

.hero .stats-item span {
  font-size: 32px;
  display: block;
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.hero .stats-item span:after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.hero .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 600;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  background-color: #1e1e1e;
  padding-top: 50px ;
  color: #c0c0c0;
  
}

.footer .footer-info .logo {
  line-height: 0;
  margin-bottom: 25px;
}

.footer .logo img {
  height: 2.5em;
  margin-bottom:0.5em;
}

.footer .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  font-family: var(--font-primary);
}

.footer .footer-info p {
  font-size: 14px;
  font-family: var(--font-primary);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #f3f6fc;
  font-size: 16px;
  color: #081828;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: rgb(79, 155, 236);
  border-color: #fff;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}


.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  color: rgba(13, 66, 255, 0.8);
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #fff;
}

.footer .footer-contact{
  padding: 0px 40px 0px 40px;
}

.footer .footer-contact p {
  line-height: 20px;
}

.footer .copyright {
  text-align: center;
  padding: 30px 0;
  border-top: 1px solid #ffffff4a;
}

.footer .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}


  .footer .footer-contact h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background: var(--color-primary);
    bottom: 0;
    left: 0;
  }
/*--------------------------------------------------------------
# Vertical lines
--------------------------------------------------------------*/
@media (min-width: 992px) {
  .vertical_line {
    content: "";
    width: 2px;
    height: 120px;
    background: var(--color-primary);
    margin: 30px 0  ;
    margin-top: 80px   ;
  }
  }
  
  @media (min-width: 992px) {
    .vertical_line_up {
      content: "";
      width: 2px;
      height: 120px;
      background: var(--color-primary);
      margin-bottom: 80px   ;
    }
    }
  

  /*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  background: #f3f9fd;
  opacity: 0.5;
  padding: 10px 0;
  text-align: center;
  z-index: -1;
}
.clients .col-lg-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients img {
  width: 30%;
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 10px 0;
  
}

.clients :hover {
  opacity: 1;
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}


@media (max-width: 575px) {
  .clients img {
    width: 30%;
  }
}


/*--------------------------------------------------------------
# Features-syn
--------------------------------------------------------------*/
.features-syn  {
  box-shadow: 0px 0 30px rgba(56, 56, 56, 0.7);
  transition: 0.3s;
  background: #000000;
  /*background: linear-gradient(90deg, rgba(27,27,27,1) 0%, rgba(42,90,93,0.9139005944174545) 50%, rgba(33,125,163,0.8046568969384629) 100%),url("../img/hero-e1.jpg");*/
}

.features-syn .feature-box h4 {
  font-size: 18px;
  color: #662050;
  margin: 0;
}
.features-syn .feature-box i {
  line-height: 0;
  background-color: #0e1d34;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}
.features-syn .feature-box:hover i {
  background: #4154f1;
  color: #fff;
}
.features-syn .feture-tabs {
  margin-top: 120px;
}
.features-syn .feture-tabs h3 {
  color: #e8e8e8;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .features-syn .feture-tabs h3 {
    font-size: 28px;
  }
}
.features-syn .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}
.features-syn .feture-tabs .nav-link {
  text-transform: uppercase;
  font-size: 15px;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}
.features-syn .feture-tabs .nav-link.active {
  color: #4154f1;
  border-bottom: 3px solid #4154f1;
}
.features-syn .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  color: #012970;
}
.features-syn .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #4154f1;
}
.features-syn .feature-icons {
  margin-top: 10px;
}
.features-syn .feature-icons h3 {
  color: #d6d7da;

  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .features-syn .feature-icons h3 {
    font-size: 28px;
  }
}
.features-syn .feature-icons .content .icon-box {
  display: flex;
}
.features-syn .feature-icons .content .icon-box img {
height: 3.2em;
margin: 10px;
}

.features-syn .feature-icons .content .icon-box img:hover {
  transition: 0.5s;
  transform:scale(110%)
  }

.features-syn .feature-icons .content .icon-box h4 {
  font-size: 22px;
  color: var(--color-primary);
  margin-bottom:0.4rem;
}
.features-syn .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}
.features-syn .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #f6f6f6;
}

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/

.values .box {
  padding: 30px;
  text-align: left;
  transition: 0.3s;
  height: 100%;
  border-radius: 5px;
  transform: scale(0.9);
  color: #2d2c2c;
  background-image: url("../img/service-patern.png");
  background-repeat: no-repeat;
  background-position: right top;

}

.values .box h4 {
  border-bottom: 1px solid #2d2d2d;
  font-size: 24px;
  color: #2f2f2f;
  font-weight: 700;
  margin-bottom: 18px;
}
/*
.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  background: #646464;
  color: #fffcfc;

}
*/



.wrapper {
  display: flex;
  justify-content: left;
  align-items: left;
}

@media(min-width:992px){
.extra_space{
  display:none
  }
}

@media (max-width: 992px) {
  .extra_space{
    content: "";
    height: 50px;

    }
}


.rotate-words {
  display: inline;
}

.rotate-words h4 {
  position: absolute;
  opacity: 0;
  color: #d8dadc;
  transition: color 0.25s ease-in-out;
  animation: rotateWord 12s linear infinite 0s;
}
 .rotate-words h4:nth-child(2) {
      animation-delay: 4s;
  }
  .rotate-words h4:nth-child(3) {
    animation-delay: 8s;
}
.rotate-words h4:nth-child(4) {
  animation-delay: 12s;
}



@keyframes rotateWord {
  0% {
      opacity: 0;
  }
  2% {
      opacity: 0;
      transform: translateY(-2rem);
  }
  5% {
      opacity: 1;
      transform: translateY(0);
  }
  17% {
      opacity: 0.5;
      transform: translateY(0);
  }
  20% {
      opacity: 0;
      transform: translateY(2rem);
  }
  80% {
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
}

.services1{
  background-color: #000000;
  
 }

.model_3dview{
 background-color: #000;
 color:white;
 align-items: center;
 justify-content: center;
 text-align: left;
 
}

.model_3dview h3{

  font-size: 32px;
  line-height: 36px;
  letter-spacing: -.03em;
  font-weight: 600;
  color: #fff;
  margin-left: 80px;
  padding: 0;
}

.model_3dview h5{
  margin-left: 80px;
  margin-top: 5px;
  color: hsla(0,0%,100%,.85);
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -.03em;
  font-weight: 600;
  margin-top: 16px;

}


.link_info{
    background: #000000;
    color:white;
    height: 100%;
  }
  

  .icon-box2 {
    display: flex;
  }
  .icon-box2 img {
  height: 1.6em;
  margin: 10px;
  }
  
  .icon-box2 img:hover {
    transition: 0.5s;
    transform:scale(110%)
    }


   .img-business{
    margin-top:40px;
    width: 600px;
   }

   .icon_check1{
    color: var(--color-primary);
   }

   select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }
  
  /* For IE10 */
  select::-ms-expand {
    display: none;
  }

.locale-switcher{
  color: #999999;
  background-color:#0000;
  border-style: hidden;
  padding-right: 40px;
  border-left:50px;
  

}


.locale-switcher:hover{
  color: #fff;
 
}
.color-switcher {
    color:black;
}

.icon-box3 {
  display: flex;
}
.icon-box3 img {
height: 1.9em;
padding-right:10px;
}

.features h6 {
  color: var(--color-primary);
  margin-bottom:0.8rem;
  margin-top:0.8rem;
}

.features p {
  margin-bottom:0.4rem;
}
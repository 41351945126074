:root {
  --font-default: Open Sans, system-ui;
  --font-primary: Open Sans, sans-serif;
  --font-secondary: Poppins, sans-serif;
  --color-default: #3a3a3a;
  --color-primary: #2fa8dd;
  --color-secondary: #0e1d34;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-default);
  color: var(--color-default);
}

select::-ms-expand {
  display: none;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: #406aff;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
}

.color-txt1 {
  color: var(--color-primary);
}

section {
  padding: 80px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-header h2 {
  text-transform: uppercase;
  color: #001973;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.section-header h2:after {
  content: "";
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  margin: auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.section-header span {
  color: #0e1d340f;
  z-index: 1;
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 700;
  line-height: 0;
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
}

.section-header p {
  z-index: 2;
  margin-bottom: 0;
  position: relative;
}

@media (max-width: 640px) {
  .section-header h2 {
    margin-bottom: 15px;
    font-size: 28px;
  }

  .section-header span {
    font-size: 38px;
  }
}

.scroll-top {
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 5%;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.scroll-top i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

.scroll-top:hover {
  color: #fff;
  background: #0c73d3cc;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

#preloader {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: #000;
  transition: all .6s ease-out;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#preloader:before, #preloader:after {
  content: "";
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, .2, .8, 1) infinite;
  position: absolute;
}

#preloader:after {
  animation-delay: -.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }

  100% {
    width: 72px;
    height: 72px;
    opacity: 0;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
  }
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

.header {
  z-index: 997;
  padding: 30px 0;
  transition: all .5s;
}

.header.sticked {
  background: #12171ee6;
  padding: 15px 0;
  box-shadow: 0 2px 20px #0e1d341a;
}

.header .logo img {
  max-height: 1.2em;
  margin-right: 6px;
}

.header .logo h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  font-family: var(--font-primary);
  margin: 0;
}

@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  .navbar li {
    position: relative;
  }

  .navbar a, .navbar a:focus {
    font-family: var(--font-primary);
    color: #fff9;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 0;
    font-size: 16px;
    transition: all .3s;
    display: flex;
  }

  .navbar a i, .navbar a:focus i {
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
  }

  .navbar > ul > li > a:before {
    content: "";
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    visibility: hidden;
    width: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: 3px;
  }

  .navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 25px;
  }

  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
  }

  .navbar .get-a-quote, .navbar .get-a-quote:focus {
    color: #cecece;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 6px 18px;
  }

  .navbar .get-a-quote:hover:before {
    visibility: hidden;
  }

  .navbar .get-a-quote:hover, .navbar .get-a-quote:focus:hover {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }

  .navbar .dropdown ul {
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    border-radius: 4px;
    margin: 0;
    padding: 10px 0;
    transition: all .3s;
    display: block;
    position: absolute;
    top: calc(100% + 30px);
    left: 14px;
    box-shadow: 0 0 30px #7f89a140;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    text-transform: none;
    color: var(--color-secondary);
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }

  .navbar .dropdown .dropdown ul {
    visibility: hidden;
    top: 0;
    left: calc(100% - 30px);
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
    top: 0;
    left: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {
  .mobile-nav-show, .mobile-nav-hide {
    display: none;
  }
}

@media (max-width: 1279px) {
  .navbar {
    width: 100%;
    max-width: 400px;
    z-index: 9997;
    transition: all .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
  }

  .navbar ul {
    z-index: 9998;
    background: #0e1d34e6;
    margin: 0;
    padding: 50px 0 10px;
    transition: all .3s;
    position: absolute;
    inset: 0;
    overflow-y: auto;
  }

  .navbar a, .navbar a:focus {
    font-family: var(--font-primary);
    color: #ffffffb3;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    transition: all .3s;
    display: flex;
  }

  .navbar a i, .navbar a:focus i {
    margin-left: 5px;
    font-size: 12px;
    line-height: 0;
  }

  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
  }

  .navbar .get-a-quote, .navbar .get-a-quote:focus {
    background: var(--color-primary);
    color: #fff;
    border-radius: 4px;
    margin: 15px;
    padding: 8px 20px;
  }

  .navbar .get-a-quote:hover, .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: #0d42ffcc;
  }

  .navbar .dropdown ul, .navbar .dropdown .dropdown ul {
    border: 1px solid #19335c;
    margin: 10px 20px;
    padding: 10px 0;
    transition: all .5s ease-in-out;
    display: none;
    position: static;
  }

  .navbar .dropdown > .dropdown-active, .navbar .dropdown .dropdown > .dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #fff;
    cursor: pointer;
    z-index: 9999;
    margin-right: 10px;
    font-size: 28px;
    line-height: 0;
    transition: all .5s;
  }

  .mobile-nav-hide {
    color: #fff;
    cursor: pointer;
    z-index: 9999;
    font-size: 32px;
    line-height: 0;
    transition: all .5s;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    z-index: 9996;
    background: #0e1d34cc;
    position: fixed;
    inset: 0;
  }
}

.call-to-action {
  background: url("syn-bmk2bg.89e02e7e.jpg") 0 0 / cover;
  padding: 100px 0;
  box-shadow: 0 0 30px #383838b3;
}

@media (min-width: 1300px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.features {
  padding-bottom: 0;
}

.features .icon-list i {
  margin-right: 10px;
  font-size: 24px;
  line-height: 1.2;
}

.features .icon-list span {
  color: #29486a;
  font-size: 18px;
}

.features .note-cloud {
  margin-top: 100px;
  margin-bottom: 80px;
  padding: 40px 0;
}

.features .note-wrap {
  position: absolute;
  top: 30px;
  left: 0;
}

@media (max-width: 998px) {
  .features .note-wrap {
    position: relative;
  }
}

.features .note-wrap img {
  width: 610px;
}

@media (max-width: 1200px) {
  .features .note-wrap img {
    width: 340px;
    margin-top: 30px;
  }
}

@media (max-width: 998px) {
  .features .note-wrap img {
    width: 100%;
  }
}

.features .phone-wrap {
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .features .phone-wrap {
    position: relative;
  }
}

.features .phone-wrap img {
  width: 680px;
}

@media (max-width: 992px) {
  .features .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .features .phone-wrap img {
    width: 70%;
    justify-content: center;
    align-items: center;
  }
}

.features .details {
  background-color: #fff;
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 40px 0;
}

.features .details h4 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
}

.features .details .btn-get-started {
  letter-spacing: 1px;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 50px;
  margin-top: 1rem;
  padding: 10px 32px;
  transition: all .5s;
  display: inline-block;
}

.features .details .btn-get-started img {
  height: 1.5em;
}

.features .details .btn-get-started:hover {
  background: #167eae;
}

.contact .info-item + .info-item {
  margin-top: 40px;
}

.contact .info-item i {
  background: var(--color-primary);
  color: #fff;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-size: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.contact .info-item h4 {
  color: var(--color-secondary);
  margin-bottom: 5px;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
}

.contact .info-item p {
  color: #0e1d34cc;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
}

.service-details .services-list {
  border: 1px solid #d3dff3;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.service-details .services-list a {
  color: var(--color-secondary);
  border-left: 3px solid #c2cbdf;
  margin: 20px 0;
  padding: 8px 0 8px 15px;
  line-height: 1;
  transition: all .3s;
  display: block;
}

.service-details .services-list a.active {
  border-color: var(--color-primary);
  font-weight: 700;
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  color: var(--color-secondary);
  font-size: 28px;
  font-weight: 700;
}

.service-details h4 {
  color: var(--color-secondary);
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.service-details ul li {
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.service-details ul i {
  color: var(--color-primary);
  margin-right: 8px;
  font-size: 20px;
}

.hero {
  width: 100%;
  min-height: 100vh;
  color: #fffc;
  background: #000 center / cover;
  padding: 120px 0 60px;
  position: relative;
}

.hero h2, .hero span {
  color: #dfdfdf;
  margin-bottom: 20px;
  padding: 0;
  font-size: 48px;
}

#hero_title_first {
  color: #2fa8dd;
}

.hero h4 {
  color: #fffc;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0;
  font-weight: lighter;
}

.hero h3 {
  color: #ecebeb;
  margin-top: 20px;
  padding: 0;
}

.hero h5 {
  color: #fffc;
  color: #7b7b7b;
  margin-bottom: 30px;
  padding: 0;
  font-weight: lighter;
}

.hero h6 {
  color: #6f6f6f;
  margin-bottom: 30px;
  padding: 0;
  font-weight: 700;
}

.hero blockquote p {
  color: #fff;
  border-left: 2px solid var(--color-primary);
  margin: 40px 0;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 575px) {
  .hero h5 {
    font-size: 15px;
  }

  .hero h4 {
    font-size: 16px;
  }

  .hero span {
    font-size: 25px;
  }
}

.hero p {
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: 400;
}

.hero form {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}

.hero form .form-control {
  border: none;
  margin-right: 10px;
  padding: 18px 20px;
  background: none !important;
  border: none !important;
}

.hero form .form-control:hover, .hero form .form-control:focus {
  box-shadow: none;
  outline: none;
}

.hero form .btn-primary {
  background-color: var(--color-primary);
  padding: 15px 30px;
}

.hero form .btn-primary:hover {
  background-color: #2756ff;
}

.hero .stats-item {
  width: 100%;
  padding: 30px;
}

.hero .stats-item span {
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 32px;
  font-weight: 700;
  display: block;
  position: relative;
}

.hero .stats-item span:after {
  content: "";
  width: 20px;
  height: 3px;
  background: var(--color-primary);
  margin: auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero .stats-item p {
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}

.footer {
  color: silver;
  background-color: #1e1e1e;
  padding-top: 50px;
  font-size: 14px;
}

.footer .footer-info .logo {
  margin-bottom: 25px;
  line-height: 0;
}

.footer .logo img {
  height: 2.5em;
  margin-bottom: .5em;
}

.footer .footer-info .logo span {
  letter-spacing: 1px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  font-family: var(--font-primary);
}

.footer .footer-info p {
  font-size: 14px;
  font-family: var(--font-primary);
}

.footer .social-links a {
  width: 30px;
  height: 30px;
  color: #081828;
  background-color: #f3f6fc;
  border: 1px solid #fff3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  transition: all .3s;
  display: flex;
}

.footer .social-links a:hover {
  color: #4f9bec;
  border-color: #fff;
}

.footer h4 {
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer .footer-links ul i {
  color: #0d42ffcc;
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  align-items: center;
  padding: 10px 0;
  display: flex;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: #fff9;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

.footer .footer-links ul a:hover {
  color: #fff;
}

.footer .footer-contact {
  padding: 0 40px;
}

.footer .footer-contact p {
  line-height: 20px;
}

.footer .copyright {
  text-align: center;
  border-top: 1px solid #ffffff4a;
  padding: 30px 0;
}

.footer .credits {
  text-align: center;
  padding-top: 4px;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}

.footer .footer-contact h4:after {
  content: "";
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 992px) {
  .vertical_line {
    content: "";
    width: 2px;
    height: 120px;
    background: var(--color-primary);
    margin: 80px 0 30px;
  }

  .vertical_line_up {
    content: "";
    width: 2px;
    height: 120px;
    background: var(--color-primary);
    margin-bottom: 80px;
  }
}

.clients {
  opacity: .5;
  text-align: center;
  z-index: -1;
  background: #f3f9fd;
  padding: 10px 0;
}

.clients .col-lg-2 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.clients img {
  width: 30%;
  filter: grayscale();
  opacity: .5;
  padding: 10px 0;
  transition: all .4s ease-in-out;
  display: inline-block;
}

.clients :hover {
  opacity: 1;
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 575px) {
  .clients img {
    width: 30%;
  }
}

.features-syn {
  background: #000;
  transition: all .3s;
  box-shadow: 0 0 30px #383838b3;
}

.features-syn .feature-box h4 {
  color: #662050;
  margin: 0;
  font-size: 18px;
}

.features-syn .feature-box i {
  background-color: #0e1d34;
  border-radius: 3px;
  margin-right: 10px;
  padding: 4px;
  font-size: 24px;
  line-height: 0;
  transition: all .3s;
}

.features-syn .feature-box:hover i {
  color: #fff;
  background: #4154f1;
}

.features-syn .feture-tabs {
  margin-top: 120px;
}

.features-syn .feture-tabs h3 {
  color: #e8e8e8;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .features-syn .feture-tabs h3 {
    font-size: 28px;
  }
}

.features-syn .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features-syn .feture-tabs .nav-link {
  text-transform: uppercase;
  color: #012970;
  border-radius: 0;
  margin-bottom: -2px;
  margin-right: 25px;
  padding: 12px 0;
  font-size: 15px;
}

.features-syn .feture-tabs .nav-link.active {
  color: #4154f1;
  border-bottom: 3px solid #4154f1;
}

.features-syn .feture-tabs .tab-content h4 {
  color: #012970;
  margin: 0;
  font-size: 18px;
}

.features-syn .feture-tabs .tab-content i {
  color: #4154f1;
  margin-right: 8px;
  font-size: 24px;
  line-height: 0;
}

.features-syn .feature-icons {
  margin-top: 10px;
}

.features-syn .feature-icons h3 {
  color: #d6d7da;
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
}

@media (max-width: 768px) {
  .features-syn .feature-icons h3 {
    font-size: 28px;
  }
}

.features-syn .feature-icons .content .icon-box {
  display: flex;
}

.features-syn .feature-icons .content .icon-box img {
  height: 3.2em;
  margin: 10px;
}

.features-syn .feature-icons .content .icon-box img:hover {
  transition: all .5s;
  transform: scale(1.1);
}

.features-syn .feature-icons .content .icon-box h4 {
  color: var(--color-primary);
  margin-bottom: .4rem;
  font-size: 22px;
}

.features-syn .feature-icons .content .icon-box i {
  color: #0245bc;
  margin-right: 15px;
  font-size: 44px;
  line-height: 44px;
}

.features-syn .feature-icons .content .icon-box p {
  color: #f6f6f6;
  font-size: 15px;
}

.values .box {
  text-align: left;
  height: 100%;
  color: #2d2c2c;
  background-image: url("service-patern.cbcdfe44.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding: 30px;
  transition: all .3s;
  transform: scale(.9);
}

.values .box h4 {
  color: #2f2f2f;
  border-bottom: 1px solid #2d2d2d;
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 700;
}

.wrapper {
  justify-content: left;
  align-items: left;
  display: flex;
}

@media (min-width: 992px) {
  .extra_space {
    display: none;
  }
}

@media (max-width: 992px) {
  .extra_space {
    content: "";
    height: 50px;
  }
}

.rotate-words {
  display: inline;
}

.rotate-words h4 {
  opacity: 0;
  color: #d8dadc;
  transition: color .25s ease-in-out;
  animation: rotateWord 12s linear infinite;
  position: absolute;
}

.rotate-words h4:nth-child(2) {
  animation-delay: 4s;
}

.rotate-words h4:nth-child(3) {
  animation-delay: 8s;
}

.rotate-words h4:nth-child(4) {
  animation-delay: 12s;
}

@keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  5% {
    opacity: 1;
    transform: translateY(0);
  }

  17% {
    opacity: .5;
    transform: translateY(0);
  }

  20% {
    opacity: 0;
    transform: translateY(2rem);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.services1 {
  background-color: #000;
}

.model_3dview {
  color: #fff;
  text-align: left;
  background-color: #000;
  justify-content: center;
  align-items: center;
}

.model_3dview h3 {
  letter-spacing: -.03em;
  color: #fff;
  margin-left: 80px;
  padding: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
}

.model_3dview h5 {
  color: #ffffffd9;
  letter-spacing: -.03em;
  margin-top: 16px;
  margin-left: 80px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.link_info {
  color: #fff;
  height: 100%;
  background: #000;
}

.icon-box2 {
  display: flex;
}

.icon-box2 img {
  height: 1.6em;
  margin: 10px;
}

.icon-box2 img:hover {
  transition: all .5s;
  transform: scale(1.1);
}

.img-business {
  width: 600px;
  margin-top: 40px;
}

.icon_check1 {
  color: var(--color-primary);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

.locale-switcher {
  color: #999;
  background-color: #0000;
  border-style: hidden hidden hidden none;
  border-left-width: 50px;
  border-left-color: currentColor;
  padding-right: 40px;
}

.locale-switcher:hover {
  color: #fff;
}

.color-switcher {
  color: #000;
}

.icon-box3 {
  display: flex;
}

.icon-box3 img {
  height: 1.9em;
  padding-right: 10px;
}

.features h6 {
  color: var(--color-primary);
  margin-top: .8rem;
  margin-bottom: .8rem;
}

.features p {
  margin-bottom: .4rem;
}

/*# sourceMappingURL=index.e33569b4.css.map */
